"use strict";
$(document).on('ready turbolinks:load', function(){



	// sticky header
	$("header").sticky({topSpacing:0});


	// meanmenu
	$('.bar').click(function(){
		$('.offcanvas__wrapper, .overlay').addClass('active');
	});
	$('.overlay').click(function(){
		$('.offcanvas__wrapper, .overlay').removeClass('active');
	});



	// owlCarousel
	$('.pertners__brand').owlCarousel({
		loop:true,
		margin:15,
		items:1,
		navText:['<i class="far fa-angle-left"></i>','<i class="far fa-angle-right"></i>'],
		nav:true,
		dots:false,
		autoplay:true,
		autoplayTimeout:2500,
		responsive:{
			0:{
				items:1,
				nav:true	
			},
			767:{
				items:3,
				nav:false
			},
			992:{
				items:5
			}
		}
	})





	// scrollToTop
	$.scrollUp({
		scrollName: 'scrollUp', // Element ID
		topDistance: '300', // Distance from top before showing element (px)
		topSpeed: 300, // Speed back to top (ms)
		animation: 'fade', // Fade, slide, none
		animationInSpeed: 200, // Animation in speed (ms)
		animationOutSpeed: 200, // Animation out speed (ms)
		scrollText: '<i class="fal fa-angle-up"></i>', // Text for element
		activeOverlay: false, // Set CSS color to display scrollUp active point, e.g '#00FFFF'
	});



});