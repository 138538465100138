// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "jquery";
require("jquery");
// window.jQuery = jquery
import "bootstrap";
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

window.jQuery = $;
window.$ = $;
window.Rails = Rails;

require("trix");
require("@rails/actiontext");
// import "./guest/vendor/jquery-1.12.4.min"
require("./slicing/bootstrap.min");
require("./slicing/jquery.nice-select.min");
require("./slicing/owl.carousel.min");
require("./slicing/jquery.sticky");
import "./slicing/jquery.scrollUp.min";
import "./slicing/main";
import "../components/product_select";
